import permission from "./permission";

export default {
    label: {
        name: "Họ và tên",
        state: "Trạng thái",
        email: "Thư điện tử",
        phone: "Số điện thoại",
        yearCourse: "Niên khóa",
        courseName: "Tên khóa học",
        status: "Trạng thái",
        fileWork: "File bài làm",
        oldPass: "Mật khẩu cũ",
        newPass: "Mật khẩu mới",
        confirmPass: "Xác nhận mật khẩu mới",
        lastUpdate: "Ngày cập nhật",
        active: "Hoạt động",
        templateChosed: "Template đã chọn",
        account: "Tài khoản",
        updateTemplateInfo: "Cập nhật thông tin dashboard",
        templateInfo: "Thông tin dashboard",
        title: "Tiêu đề",
        linkEmbed: "Link Embed",
        permission: {
            object: "Đối tượng tác động",
            name: "Tên quyền"
        },
        role: "Nhóm quyền",
        type: "Loại tài khoản",
        createdTime: "Thời gian tạo",
        quickCode: "Mã đăng nhập nhanh"
    },
    text: {
        inputAccountName: "Nhập họ và tên",
        inputEmail: "Nhập thư điện tử",
        inputPhone: "Nhập số điện thoại",
        selectYearCourse: "Chọn niên khóa",
        statusNonRegister: "Chưa đăng ký",
        statusRegistered: "Đã đăng ký",
        statusSubmitted: "Đã nộp bài",
        selectState: "Chọn trạng thái",
        inputTitleTemplate: "Nhập tiêu đề",
        inputLinkEmbed: "Nhập link embed",
        selectChoosedTemplate: "Đã chọn dashboard mẫu"
    },
    type: {
        all: "All",
        admin: "Admin",
        customer: "Khách hàng",
        agency: "Đại lý",
        trial: "Dùng thử"
    },
    message: {
        notFoundTemplate: "Khách hàng chưa chọn template"
    }
}
